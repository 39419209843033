import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUp from './Auth/SignUp';
import Login from './Auth/Login';
import Dashboard from './Dashboard/Dashboard';
import Private from './Routing/Private';
import Public from './Routing/Public';
import { Toaster } from 'react-hot-toast';
import PrivateLayout from './Layouts/PrivateLayout';
import Bindapi from './Blindapi/Bindapi';
import Deposit from './Blindapi/Deposit';
import OpenPositon from "./Trade/OpenPosition"
import ClosePosition from './Trade/ClosePosition';
import Forget from './Auth/Forget';
import Verfication_forget from './Auth/verficationforgetotp'
import Verfication from './Auth/Verfication';
// import Exchagedkey from './exchangedkey/Exchagedkey';
import Verfications from './Auth/Verfications';
import Openspotposition from './trader/Openpositions';
import Closespotposition from './trader/Closepositions';
import Openview from './Trade/Openview'
import Openspotview from './trader/Openspotview'
import Activity from './activity/Activity'
import Home from './Home/Home'
import Profile from './Home/Profile';
import Team from './Home/Team'
import Direct from './Home/Directteam'
import Tranctattion from './Dashboard/Tranctattion'
import Wallet from './Dashboard/Wallet'
// import Activatedpage from './Home/Activatedpage';
import FundTransfer from './Dashboard/Fundertransfer'
import Ticket from './Dashboard/Ticket'
import Bonus from './Dashboard/DashBonus';
import Club from './Dashboard/Club'
import DashBonus from './Dashboard/DashBonus'
import Layalty from './Dashboard/Layalty'
import Withdraw from './Dashboard/Withdraw'
import Success from './Dashboard/Success'
import Pop from './Auth/Pop'
import Pageotp from './Auth/Pageotp'
import Withdrawhistory from './Trade/Withdrawhistory'
import Marketdata from './Dashboard/Marketdata'
import Depositpage from './Trade/Depositpage'
import Profit from './Trade/Profit'
import Level from './Dashboard/Level'

const App = () => {



  return (
    <>

      <Router>
        <Routes>

          {/* Private routes */}
          <Route element={<Private />}>
            <Route path="/dashboard" element={<PrivateLayout><Dashboard /></PrivateLayout>} />
            <Route path="/bonus" element={<PrivateLayout><Bonus /></PrivateLayout>} />
            <Route path="/bindapi" element={<PrivateLayout><Bindapi /></PrivateLayout>} />
            <Route path="/deposit" element={<PrivateLayout><Deposit /></PrivateLayout>} />
            <Route path="/open_position" element={<PrivateLayout><OpenPositon /></PrivateLayout>} />
            <Route path="/close_position" element={<PrivateLayout><ClosePosition /></PrivateLayout>} />
            {/* <Route path="/exchagedkey" element={<PrivateLayout><Exchagedkey /></PrivateLayout>} /> */}
            <Route path="/closespotposition" element={<PrivateLayout><Closespotposition /></PrivateLayout>} />
            <Route path="/openspotposition" element={<PrivateLayout><Openspotposition /></PrivateLayout>} />
            <Route path='/openview' element={<PrivateLayout><Openview /></PrivateLayout>} />
            <Route path='/openspotview' element={<PrivateLayout><Openspotview /></PrivateLayout>} />
            <Route path='/activity' element={<PrivateLayout><Activity /></PrivateLayout>} />
            <Route path='/profile' element={<PrivateLayout><Profile /></PrivateLayout>} />
            <Route path='/team' element={<PrivateLayout><Team /></PrivateLayout>} />
            <Route path='/direct' element={<PrivateLayout><Direct /></PrivateLayout>} />
            <Route path='/tranctattion' element={<PrivateLayout><Tranctattion /></PrivateLayout>} />
            <Route path='/wallet' element={<PrivateLayout><Wallet /></PrivateLayout>} />
            <Route path='/fundtransfer' element={<PrivateLayout><FundTransfer /></PrivateLayout>} />
            <Route path='/ticket' element={<PrivateLayout><Ticket /></PrivateLayout>} />
            <Route path='/club' element={<PrivateLayout><Club /></PrivateLayout>} />
            <Route path='/dashBonus' element={<PrivateLayout><DashBonus /></PrivateLayout>} />
            < Route path='/layalty' element={<PrivateLayout><Layalty /></PrivateLayout>} />
            < Route path='/mithdraw' element={<PrivateLayout><Withdraw /></PrivateLayout>} />
            < Route path='/success' element={<PrivateLayout><Success /></PrivateLayout>} />
            < Route path='/withdrawhistory' element={<PrivateLayout><Withdrawhistory /></PrivateLayout>} />
            < Route path='/marketdata' element={<PrivateLayout><Marketdata /></PrivateLayout>} />
            < Route path='/depositpage' element={<PrivateLayout><Depositpage /></PrivateLayout>} />
            < Route path='/profit' element={<PrivateLayout><Profit /></PrivateLayout>} />
            < Route path='/level/:level' element={<PrivateLayout><Level /></PrivateLayout>} />






            {/* < Route path='/Pageotp' element={<PrivateLayout><Pageotp /></PrivateLayout>} /> */}
            {/* < Route path='/pop' element={<PrivateLayout><Pop /></PrivateLayout>} /> */}


          </Route>

          {/* Public routes */}
          <Route element={<Public />}>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/Pageotp" element={<Pageotp />} />
            <Route path="/Verfication" element={<Verfication />} />
            <Route path="/Forget" element={<Forget />} />
            <Route path="/Verfication_forget" element={<Verfication_forget />} />
            <Route path="/Verfications" element={<Verfications />} />
            < Route path='/pop' element={<Pop />} />
          </Route>
        </Routes>
        <Toaster />
      </Router>

    </>

  );
}

export default App;


